import { create } from "zustand";
import { combine } from "zustand/middleware";

export const useContactForm = create(
  combine(
    {
      isOpen: false,
    },
    (set) => ({
      open: () => set({ isOpen: true }),
      close: () => set({ isOpen: false }),
      toggle: () => set((state) => ({ isOpen: !state.isOpen })),
    })
  )
);
